import { getUser } from '../utilities/userUtilities'
import { authenticatedFetch } from './base'

export const signUp = (email, username, password) => {
	fetch('/api/registration', {
		method: 'post',

		body: JSON.stringify({ userName: username, email: email, password: password }),
		headers: { 'content-type': 'application/json' }
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {})
}

export const completePhoneVerification = (code, mfaSign) => {
	return fetch(`/api/authorization/verifyPhone/code/${code}`, {
		method: 'post',
        headers: mfaSign ? { 'mfaSign': mfaSign } : {}
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(function (response) {
            return response
        })
}

export const registerUser = (user) => {
	return fetch(`/api/registration`, {
		method: 'post',
		body: JSON.stringify(user),
		headers: { 'content-type': 'application/json' }
	}).then(function(response) {
		return response.json()
	})
}

export const registerUserEmailVerified = (user) => {
	return fetch(`/api/registration/verified`, {
		method: 'post',
		body: JSON.stringify(user),
		headers: { 'content-type': 'application/json' }
	}).then(function(response) {
		return response.json()
	})
}

export const confirmUser = (email, code) => {
	return fetch(`/api/registration/confirm`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ code: code, email: email })
	}).then(function(response) {
		return response.json()
	})
}

export const signIn = (email, password, rememberMe, language) => {
	return fetch(`/api/authorization`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email?.trim(), password: password?.trim(), rememberMe: rememberMe, language: language })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const signOut = () => {
	return fetch(`/api/authorization/signout`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	}).then(function(response) {
		return response
	})
}

export const requestForgotPasswordEmail = (email) => {
	return fetch(`/api/authorization/requestpasswordchange`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email.trim() })
	}).then(function(response) {
		return response
	})
}

export const resendConfirmationCode = (email) => {
	return fetch(`/api/authorization/resendConfirmationCode/${email}`, {
		method: 'post',
		headers: { 'content-type': 'application/json' }
	}).then(function(response) {
		return response
	})
}

export const confirmForgotPassword = (email, code, password) => {
	return fetch(`/api/authorization/confirmpasswordchange`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email?.trim(), code: code, password: password })
	}).then(function(response) {
		return response.json()
	})
	.then(function(data) {
		return data
	})
}

export const refreshToken = () => {
	var user = getUser()
	if (user) {
        return fetch(`/api/authorization/refresh/${user.id}`, { method: 'GET' });
    }
}

export const changePassword = (email, oldPassword, newPassword) => {
	return fetch(`/api/authorization/changepassword`, {
		method: 'post',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify({ email: email, newPassword: newPassword, oldPassword: oldPassword?.trim() })
	})
		.then(function(response) {
			return response.json()
		})
		.then(function(data) {
			return data
		})
}

export const checkSubscriptionUpdates = () => {
	return authenticatedFetch('/api/authorization/checksubscriptionupdates', { method: 'GET' })
		.then(function (response) {
			return response.json()
		})
		.then(function (data) {
			return data
		});
}